.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.error {
    color: red;
    font-style: italic;
}

.formElement {
    padding: 10px;
}
