.headerRow{
    clear: both;
    cursor: pointer;
    border-bottom:1px solid lightgray;
}

.headerItem{
    float: left;
    padding-left: 10px;
    font-weight: bold;
    text-align: left;
}

.listHeader{
    float: left;
    padding-left: 10px;
    font-weight: bold;
    text-align: left;
}

.headerEnd{
    clear: both;
}

.listRow{
    cursor: pointer;
    border-bottom:1px solid lightgray;
}

.listItem{
    float: left;
    padding-left: 10px;
    text-align: left;
    overflow: auto;
}

.listEnd{
    clear: both;
}
.subList{
    clear: both;
    margin-left: 20px;
}

.userContextHolder
{
    text-align: left;
    clear: both;
    border-radius: 10px;
    background: aliceblue;
    margin: 10px;
    padding: 10px;

}

.formHolder
{
    border: solid 1px;
    width: 80%;
    margin: 0 auto;
}


.fieldHolder
{
    padding: 10px;
}

.fieldEnd
{
    clear: both;
}

.fieldLabel
{
    font-weight: bold;
    float: left;
    margin-right: 5px;
    width: 160px;
    text-align: right;
}

.fieldLabelLeft
{
    font-weight: bold;
    float: left;
    margin-right: 5px;
    width: 160px;
    text-align: left;
}

.fieldInput
{
    float: left;
}

.radioField
{
    padding: 5px;
    float: left;
}

.sectionHead
{
    border-radius: 10px;
    background: lightgray;
    margin: 10px;
}

.sectionBody
{
    padding: 1px;
}

.document
{
    border: solid 1px;
    margin: 10px;
    padding: 10px;
}

.heading
{
    font-weight: bold;
    margin: 10px;
    padding: 10px;
}

.paragraph
{
    font-weight: normal;
    margin: 10px;
    padding: 10px;
}

documentlist{
    list-style: square inside;
}
