.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.email-template {
  font-family: 'Roboto', sans-serif;
  width: 100%;
  justify-content: center
}

.footer_link {
  position: static;
  width: 90px;
  height: 26px;
  left: 62px;
  top: 9.5px;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  /* identical to box height, or 162% */

  text-align: center;
  text-decoration-line: underline;

  color: #000000;


  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 17px;
}

.content-email {
  align-self: center;
  align-self: center;
  width: 100vw;
  height: fit-content;
  max-width: 960px;
  max-height: 864px;
}

.header {
  width: calc(100% - 80px);
  padding: 19px 40px;
  grid-template-rows: auto auto;
  grid-gap: 34px;
  padding-bottom: 19px;
  border-bottom: 2px solid #e5e5e5;
}

.header-text {
  font-size: 20px;
  font-weight: 500;
}

.content {
  padding-top: 45px;
  grid-template-rows: auto auto auto auto;
  grid-gap: 47px;
}

.content-text {
  width: 80%;
  max-width: 623px;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  padding-bottom: 386px;
}

.content-text>span>p {
  font-weight: 700;
}


.footer {
  padding-top: 45px;
}

.footer>.image {
  padding-top: 12px;
  background-color: #EFEFEF;
  height: 57px;
  text-align: center;
}

.footer>.footer-text>span {
  font-size: 14px;
  font-weight: 400;
  color: #808080;
  padding: 22px 0;
}

.grid {
  display: grid;
  display: -moz-grid;
}

.center {
  align-self: center;
  justify-self: center;
}

button:focus {
  outline: none;
}

.actDetail
{
  padding: 3px;
}
