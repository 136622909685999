.menuItem {
    cursor: pointer;
    margin-left: 5px;
    padding: 2px;
    float: left;
    font-size: 10px;
    border: 1px outset ;
    border-radius: 15px;
}

.currentMenuItem {
    cursor: pointer;
    padding: 2px;
    margin-left: 5px;
    float: left;
    font-weight: bold;
    font-size: 10px;
    border: 1px  inset ;
    background: #EFEFEF;
    border-radius: 15px;
}

.menuBar {
    float: left;
    height: 40px;
    width: 100%;
    padding-left: 10px;
    border: 0px solid black;
    overflow: hidden;
    text-overflow: ellipsis;
}
.loginBar {
    position: relative;
    /*display: flex;*/
    align-items: center;
    float: left;
    overflow-wrap: break-word;
    height: 80px;
    width: 40%;
    padding-left: 2px;
    border: 0px solid black;
    /*overflow: hidden;*/
    /*text-overflow: ellipsis;*/
}

.logoutBar {
    float: right;
    overflow-wrap: break-word;
    height: 30px;
    width: 10%;
    padding: 2px;
    border: 0px solid black;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 11px;
}

.clear {
    clear: both;
}
.navWrapper {
    clear: both;
    padding: 5px;
    margin: 20px;
    width: 95%;
    border: 2px solid black;
}

.loginItem {
    cursor: pointer;
    padding: 2px;
    float: left;
    font-size: 12px;
    color: black;
    font-style: normal ;
    font-weight : normal;
}

